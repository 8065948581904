
import moment from 'moment';

export default class UtilityService {
  static timeString(s: number): string {
    const h = Math.floor(s / 3600);
    s = s % 3600;
    const m = Math.floor(s / 60);
    s = Math.floor(s % 60);
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  }

  static dateTimeString(date: Date) {
    const d = String(date.getDate()).padStart(2, '0');
    const m = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const y = date.getFullYear();
    const h = String(date.getHours()).padStart(2, '0');
    const i = String(date.getMinutes()).padStart(2, '0');
    const s = String(date.getSeconds()).padStart(2, '0');

    return `${y}-${m}-${d} ${h}:${i}:${s}`;
  }

  static getDateUTC(date?: string | undefined) {
    return date ? moment.utc(date) : moment.utc();
  }

  static momentToString(d: moment.Moment, format?: string) {
    format = format ? format : 'YYYY-MM-DD HH:mm:ss';
    return d.format(format);
  }

  static fromUTCtoLocal(date?: string | undefined): string | undefined {
    return date ? UtilityService.momentToString(UtilityService.getDateUTC(date).local()) : date;
  }
}
