











































































import { Timer } from '@/interfaces/models/Timer';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UtilityService from '@/services/UtilityService';
import TimerService from '@/services/TimerService';
import MarkerService from '@/services/MarkerService';
import MarkersList from '@/components/Markers/MarkersList.vue';
import { DTO } from '@/interfaces/DTO';

@Component({
  components: {
    MarkersList,
  },
})
export default class TimerDetail extends Vue {
  @Prop() private timer!: Timer;
  private timerService: TimerService = new TimerService();
  private markerService: MarkerService = new MarkerService();

  private timerSaving: boolean = false;

  private showDialog: boolean = false;
  private deleting: boolean = false;
  private toDeleteTimer: Timer | null = null;

  private counterInterval: number | undefined;
  private elapsedSeconds: number = 0;
  private elapsed: string = '';

  private markerSaving: boolean = false;
  private markerName: string = '';

  mounted() {
    if (!this.timer.stopped_at) {
      this.counterInterval = setInterval(() => {
        this.elapsedSeconds = Math.round(new Date().getTime() / 1000) - this.timer.started_at_ts;
        this.elapsed = UtilityService.timeString(this.elapsedSeconds);
      }, 1000);
    }
  }

  private closeTimer() {
    if (this.counterInterval) {
      clearInterval(this.counterInterval);
    }
    this.$emit('timerSelected', null);
  }

  private updateMarkers() {
    this.updateMarkersList();
  }

  private updateMarkersList() {
    this.$emit('timerUpdated', this.timer.uuid);
  }

  private saveTimer() {
    this.timerSaving = true;
    const toSave: Timer = { ...this.timer };
    delete toSave['markers'];
    this.timerService
      .update(this.timer.uuid, toSave)
      .then((response) => {
        if (response.status == 200) {
          this.$emit('timerUpdated', toSave.uuid);
          return;
        }
        throw new Error('Call error');
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.timerSaving = false;
      });
  }

  private addMarker() {
    const toSaveMarker: DTO.Marker.Requests.Insert = {
      name: this.markerName,
    };
    this.markerSaving = true;
    this.markerService
      .insert(this.timer.uuid, toSaveMarker)
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.updateMarkersList();
        }
        throw new Error();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.markerSaving = false;
      });
    setTimeout(() => {
      this.markerName = '';
      this.markerSaving = false;
    }, 4000);
  }

  private stopTimer() {
    this.timerService
      .stop(this.timer.uuid)
      .then((response) => {
        if (response.status === 200) {
          clearInterval(this.counterInterval);
          if (response.data) {
            this.$emit('timerUpdated', this.timer.uuid);
          }
          return;
        }
        throw new Error();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  private deleteTimerDialog(timer: Timer) {
    this.toDeleteTimer = timer;
    this.showDialog = true;
  }

  private deleteTimer() {
    if (this.toDeleteTimer) {
      this.deleting = true;
      this.timerService
        .delete(this.toDeleteTimer.uuid)
        .then((response) => {
          if (response.status === 200) {
            this.$emit('updateTimers');
            this.closeTimer();
            return;
          }
          throw new Error('Call error');
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.deleting = false;
          this.toDeleteTimer = null;
        });
    }
    this.showDialog = false;
  }

  getDateString(date?: string): string | undefined {
    return UtilityService.fromUTCtoLocal(date);
  }
}
