

































































import { Marker } from '@/interfaces/models/Marker';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MarkerService from '@/services/MarkerService';
import UtilityService from '@/services/UtilityService';
import { Timer } from '@/interfaces/models';

@Component
export default class MarkersList extends Vue {
  @Prop() private timerUuid!: string;
  @Prop() private timer!: Timer;
  @Prop() private markers!: Marker[];
  private markerService: MarkerService = new MarkerService();
  private toDeleteMarker: Marker | null = null;
  private showDialog: boolean = false;
  private deleting: boolean = false;

  private isEdit: string | null = null;

  private refreshInterval: number | undefined;

  mounted() {
    if (!this.timer.stopped_at) {
    this.refreshInterval = setInterval(() => {
        if (!this.isEdit) {
          this.updateMarkers();
        }
      }, 15000);
    }
  }

  private deleteMarkerDialog(e: Event, marker: Marker) {
    this.toDeleteMarker = marker;
    this.showDialog = true;
  }

  private deleteMarker() {
    if (this.toDeleteMarker) {
      this.deleting = true;
      this.markerService
        .delete(this.timerUuid, this.toDeleteMarker.uuid)
        .then((response) => {
          if (response.status === 200) {
            this.updateMarkers();
            return;
          }
          throw new Error('Call error');
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.deleting = false;
          this.toDeleteMarker = null;
        });
    }
    this.showDialog = false;
  }

  private updateMarkers() {
    this.$emit('updateMarkers');
  }

  private setEditMode(marker: Marker) {
    this.isEdit = marker.uuid;
    const inputEl = 'marker_' + this.isEdit;
    // console.log(this.$refs[inputEl][0].$el);
  }

  private saveMarkerName(marker: Marker) {
    this.markerService.update(this.timerUuid, marker.uuid, marker).then(
      (response) => {
        if (response.status === 200) {
          this.updateMarkers();
          this.isEdit = null;
          return;
        }
        throw new Error();
      },
      (err) => {
        console.error(err);
      },
    );
  }

  getformattedName(name: string): string {
    if (name.match(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
      return UtilityService.momentToString(UtilityService.getDateUTC(name).local());
    }
    return name;
  }

  getDateString(date?: string): string | undefined {
    return UtilityService.fromUTCtoLocal(date);
  } 
}
