import axios from 'axios';
import authHeader from './Auth/AuthHeader';
import { environment } from '@/environments/environment';
import { DTO } from '@/interfaces/DTO';
import { MarkerServiceInterface } from '@/interfaces/services/MarkerServiceInterface';
import { Marker } from '@/interfaces/models/Marker';

export default class MarkerService implements MarkerServiceInterface {
  list = (timerUuid: string, page?: number) => {
    if (!page || page <= 0) {
      page = 1;
    }

    return axios.get<DTO.Marker.Responses.List>(`${environment.baseUrl}/timers/${timerUuid}/markers`, {
      headers: authHeader(),
      data: { page: page },
    });
  };
  get = (timerUuid: string, uuid: string) => {
    return axios.get<Marker>(`${environment.baseUrl}/timers/${timerUuid}/markers/${uuid}`, {
      headers: authHeader(),
    });
  };
  insert = (timerUuid: string, data: DTO.Marker.Requests.Insert) => {
    return axios.post<Marker>(`${environment.baseUrl}/timers/${timerUuid}/markers`, data, {
      headers: authHeader(),
    });
  };
  update = (timerUuid: string, uuid: string, data: DTO.Marker.Requests.Update) => {
    return axios.patch<Marker>(`${environment.baseUrl}/timers/${timerUuid}/markers/${uuid}`, data, {
      headers: authHeader(),
    });
  };
  delete = (timerUuid: string, uuid: string) => {
    return axios.delete<DTO.Marker.Responses.Delete>(`${environment.baseUrl}/timers/${timerUuid}/markers/${uuid}`, {
      headers: authHeader(),
    });
  };
}
