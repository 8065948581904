import axios from 'axios';
import authHeader from './Auth/AuthHeader';
import { environment } from '@/environments/environment';
import { DTO } from '@/interfaces/DTO';
import { TimerServiceInterface } from '@/interfaces/services/TimerServiceInterface';
import { Timer } from '@/interfaces/models/Timer';

export default class TimerService implements TimerServiceInterface {
  list = (page?: number) => {
    if (!page || page <= 0) {
      page = 1;
    }

    return axios.get<DTO.Timer.Responses.List>(`${environment.baseUrl}/timers/page/${page}`, {
      headers: authHeader(),
    });
  };
  get = (uuid: string) => {
    return axios.get<Timer>(`${environment.baseUrl}/timers/${uuid}`, {
      headers: authHeader(),
    });
  };
  insert = (data: DTO.Timer.Requests.Insert) => {
    return axios.post<Timer>(`${environment.baseUrl}/timers`, data, {
      headers: authHeader(),
    });
  };
  update = (uuid: string, data: DTO.Timer.Requests.Update) => {
    return axios.patch<Timer>(`${environment.baseUrl}/timers/${uuid}`, data, {
      headers: authHeader(),
    });
  };
  delete = (uuid: string) => {
    return axios.delete<DTO.Timer.Responses.Delete>(`${environment.baseUrl}/timers/${uuid}`, {
      headers: authHeader(),
    });
  };
  stop = (uuid: string) => {
    return axios.post<Timer>(
      `${environment.baseUrl}/timers/${uuid}/stop`,
      {},
      {
        headers: authHeader(),
      },
    );
  };
}
