




































































import { DTO } from '@/interfaces/DTO';
import { Timer } from '@/interfaces/models/Timer';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TimerService from '@/services/TimerService';
import UtilityService from '@/services/UtilityService';

@Component
export default class TimersList extends Vue {
  @Prop() private list!: DTO.Timer.Responses.List;
  private timerService: TimerService = new TimerService();

  private showDialog: boolean = false;
  private deleting: boolean = false;
  private toDeleteTimer: Timer | null = null;

  private rowSelected(e: Event, uuid: string) {
    const selectedTimer: Timer | undefined = this.list.items.find((i) => i.uuid === uuid);
    if (selectedTimer) {
      this.$emit('timerSelected', selectedTimer);
    }
  }

  private emitCreateTimer() {
    this.$emit('createTimer');
  }

  private updateTimers() {
    this.$emit('updateTimers');
  }

  private deleteTimerDialog(e: Event, timer: Timer) {
    this.toDeleteTimer = timer;
    this.showDialog = true;
  }

  private deleteTimer() {
    if (this.toDeleteTimer) {
      this.deleting = true;
      this.timerService
        .delete(this.toDeleteTimer.uuid)
        .then((response) => {
          if (response.status === 200) {
            this.$emit('updateTimers');
            return;
          }
          throw new Error('Call error');
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.deleting = false;
          this.toDeleteTimer = null;
        });
    }
    this.showDialog = false;
  }

  getformattedName(name: string): string {
    if (name.match(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/)) {
      return UtilityService.momentToString(UtilityService.getDateUTC(name).local());
    }
    return name;
  }

  getDateString(date?: string): string | undefined {
    return UtilityService.fromUTCtoLocal(date);
  }       
}
