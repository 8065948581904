












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Pagination extends Vue {
  @Prop() currentPage!: number | null;
  @Prop() lastPage!: number | null;

  private clickLeft(): void {
    this.$emit('leftEmitted');
  }

  private clickRight(): void {
    this.$emit('rightEmitted');
  }
}
