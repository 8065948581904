




































import Vue from 'vue';
import Component from 'vue-class-component';
import { DTO } from '@/interfaces/DTO';
import { Timer } from '@/interfaces/models/Timer';
import TimerService from '@/services/TimerService';
import TimersList from '@/components/Timers/TimersList.vue';
import TimerDetail from '@/components/Timers/TimerDetail.vue';
import Pagination from '@/components/Pagination.vue';
import UtilityService from '@/services/UtilityService';

@Component({
  components: {
    TimersList,
    TimerDetail,
    Pagination,
  },
})
export default class Timers extends Vue {
  private timerService: TimerService = new TimerService();
  private list: DTO.Timer.Responses.List | null = null;
  private selectedTimer: Timer | null = null;

  mounted() {
    this.updateList();
  }

  private selectTimerEvent(timer?: Timer) {
    this.selectedTimer = timer ? timer : null;
  }

  private updateTimers() {
    this.updateList();
  }

  private updateList(page?: number) {
    if (!page && this.list?.currentPage) {
      page = this.list.currentPage;
    }

    this.timerService
      .list(page)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          this.list = response.data;
          return;
        }
        throw new Error('Call error');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  private timerUpdated(timer_uuid: string) {
    this.timerService.get(timer_uuid).then(
      (response) => {
        if (response.status === 200) {
          const newTimer: Timer = response.data;
          if (this.list) {
            let t = this.list.items.find((i) => i.uuid === timer_uuid);
            if (t) {
              this.list.items[this.list?.items.indexOf(t)] = newTimer;
            }
          }
          this.selectedTimer = newTimer;
          return;
        }
        throw new Error();
      },
      (err) => {
        console.error(err);
      },
    );
  }

  private createTimer() {
    const nowStr = UtilityService.momentToString(UtilityService.getDateUTC());

    this.timerService
      .insert({
        name: nowStr,
      })
      .then(
        (response) => {
          if (response.status === 200) {
            return this.updateList();
          }
          throw new Error();
        },
        (err) => {
          console.error(err);
        },
      );
  }

  private prevPage() {
    if (this.list?.currentPage && this.list.currentPage > 1) {
      this.updateList(this.list.currentPage - 1);
    }
  }

  private nextPage() {
    if (this.list?.currentPage && this.list.lastPage && this.list.currentPage < this.list.lastPage) {
      this.updateList(this.list.currentPage + 1);
    }
  }
}
